import React from 'react'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'

import dinersRoutes from 'routes/diners'
import customersRoutes from 'routes/customers'
import adminRoutes from 'routes/admin'
import ErrorBoundary from 'views/ErrorBoundary'
import LoginPage from 'components/LoginPage'

const router = createBrowserRouter([
  ...dinersRoutes,
  ...customersRoutes,
  ...adminRoutes,
  {
    path: '/',
    element: <Navigate replace to="/diners/kitchens-list" />,
  },

  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '*',
    errorElement: <ErrorBoundary />,
    element: (
      <main style={{ padding: '1rem' }}>
        <p>There&apos;s nothing here!</p>
      </main>
    ),
  },
])

export default function App() {
  return <RouterProvider router={router} />
}
