import axios from 'axios'
import React from 'react'

const adminUserContext = React.createContext()

export function ProvideUser({ children }) {
  const adminUser = useProvideUser()
  return (
    <adminUserContext.Provider value={adminUser}>
      {children}
    </adminUserContext.Provider>
  )
}

export const useUser = () => {
  return React.useContext(adminUserContext)
}

function useProvideUser() {
  const initialLoad = React.useRef(false)
  const [user, setUser] = React.useState()
  const isAdmin = user?.role === 'admin'

  React.useEffect(() => {
    const initializeValidation = async () => {
      try {
        const { data } = await axios.get('/api/auth/user')
        setUser(data)
      } catch (error) {
        setUser()
      }
      initialLoad.current = true
    }
    initializeValidation()
  }, [])

  const signin = async (email, password) => {
    try {
      const { data: updatedUser } = await axios.post('/api/auth/login', {
        email,
        password,
      })
      setUser(updatedUser)
      return updatedUser
    } catch (error) {
      setUser()
      throw error
    }
  }

  const signout = async () => {
    // await axios.post('/api/auth/logout')
    setUser()
  }

  return {
    initialLoad: initialLoad.current,
    user,
    signin,
    signout,
    isAdmin,
  }
}
