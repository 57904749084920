import {
  AppBar,
  Box,
  CssBaseline,
  Paper,
  Stack,
  ThemeProvider,
  Toolbar,
  Typography,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import DietsChart from 'components/AdminView/AnalyticsPage/DietsChart'
import DishMatchList from 'components/AdminView/AnalyticsPage/DishMatchList'
import KitchenSelector from 'components/AdminView/AnalyticsPage/KitchenSelector'
import BrandSelector from 'components/BrandSelector'
import Logo from 'components/Logo'
import MenuSelector from 'components/MenuSelector'
import { useBrandsByOrganizationId } from 'hooks/brands'
import { useKitchensByBrandId } from 'hooks/kitchens'
import { useOrganizationById } from 'hooks/organizations'
import { useGetTranslatedLabel } from 'hooks/useTranslatedLabel'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import getTheme from 'theme'
import TotalDiners from './TotalDiners'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { enUS, he as heIL } from 'date-fns/locale'
import { useLanguage } from 'contexts/language'
import DinersCharts from './DinersCharts'

const locales = { 'en-US': enUS, 'he-IL': heIL }

export default function StandaloneAnalyticsPage() {
  const { organizationId } = useParams()
  const { t } = useTranslation()
  const { language } = useLanguage?.() || { language: 'en-US' }
  const getTranslatedLabel = useGetTranslatedLabel()

  const [brandId, setBrandId] = React.useState()
  const [menuId, setMenuId] = React.useState()
  const [kitchenId, setKitchenId] = React.useState()
  const [date, setDate] = React.useState(null)
  const [updatedQuery, setUpdatedQuery] = React.useState(null)

  const { organization, isLoading: isLoadingOrganization } =
    useOrganizationById(organizationId)
  const { brands, isLoading: isLoadingBrands } =
    useBrandsByOrganizationId(organizationId)
  const { kitchens, isLoading: isLoadingKitchens } = useKitchensByBrandId(
    brandId || brands?.[0]?._id
  )

  React.useEffect(() => {
    if (!isLoadingOrganization && !isLoadingBrands && !isLoadingKitchens) {
      setBrandId(brands?.[0]?._id)
      setKitchenId(kitchens?.[0]?._id)

      setUpdatedQuery({
        kitchenId: kitchens?.[0]?._id,
        brandId: brands?.[0]?._id,
        menuId,
        date,
        organizationId,
      })
    }
  }, [
    brands,
    date,
    isLoadingBrands,
    isLoadingKitchens,
    isLoadingOrganization,
    kitchens,
    menuId,
    organizationId,
  ])

  return (
    <ThemeProvider theme={getTheme(false)}>
      <Box sx={{ display: 'flex', height: '100dvh', position: 'relative' }}>
        <CssBaseline />
        <Stack p={2} flex={1} height="100%">
          <AppBar>
            <Stack direction="row" m={2}>
              <Logo />
              <Box width={5} />
              <Typography variant="subtitle1">Analytics</Typography>
              <Box flex={1} />

              {organization && (
                <Typography variant="h5">
                  {getTranslatedLabel(organization)}
                </Typography>
              )}
            </Stack>
          </AppBar>

          <Toolbar />

          <Stack direction="row">
            <Paper
              sx={{
                position: 'relative',
                zIndex: 111,
                bgcolor: grey[100],
              }}
            >
              <Stack spacing={2} p={2} position={'sticky'} top={70}>
                <Typography variant="h5">query</Typography>
                <BrandSelector
                  value={brandId}
                  onChange={setBrandId}
                  organizationId={organizationId}
                  disabled={brands?.length === 1}
                />

                <KitchenSelector
                  value={kitchenId}
                  onChange={setKitchenId}
                  brandId={brandId}
                  disabled={kitchens?.length === 1}
                />

                <MenuSelector
                  value={menuId}
                  onChange={(newValue) => {
                    setMenuId(newValue)
                    setDate(null)
                  }}
                  brandId={brandId}
                  disabled={!brandId}
                />

                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={locales[language]}
                >
                  <DateTimePicker
                    label={t('date')}
                    format={'dd/MM/yyyy HH:mm'}
                    value={date}
                    onChange={(newValue) => {
                      setDate(newValue)
                      setMenuId(null)
                    }}
                    sx={{ minWidth: 200 }}
                  />
                </LocalizationProvider>

                <TotalDiners {...updatedQuery} />
              </Stack>
            </Paper>

            <Stack direction="row" flex={1}>
              {updatedQuery && (
                <Stack flex={1}>
                  <Stack
                    overflow={'hidden'}
                    flex={1}
                    maxHeight={700}
                    minHeight={700}
                  >
                    <DinersCharts {...updatedQuery} />
                  </Stack>

                  <Stack
                    overflow={'hidden'}
                    flex={1}
                    maxHeight={700}
                    minHeight={700}
                  >
                    <DishMatchList query={updatedQuery} />
                  </Stack>
                  <Stack
                    overflow={'hidden'}
                    flex={1}
                    direction="row"
                    maxHeight={700}
                    height={700}
                  >
                    <DietsChart {...updatedQuery} />
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </ThemeProvider>
  )
}
