import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Typography,
  Link,
  // Dialog,
  // DialogContent,
  // DialogActions,
  // Button,
  Checkbox,
} from '@mui/material'

export default function RegistrationDisclaimers({ isApproved, setIsApproved }) {
  const { t } = useTranslation()
  return (
    <>
      <Box my={2} fontSize={'0.75rem'} textAlign={'center'} lineHeight={1.5}>
        {setIsApproved && (
          <Checkbox
            size="small"
            checked={isApproved}
            onChange={() => setIsApproved((current) => !current)}
          />
        )}
        <Typography variant="body" fontSize={'0.75rem'}>
          {t(
            setIsApproved
              ? 'i_agree_to_the'
              : 'terms_of_use_and_privacy_policy_label'
          )}
        </Typography>{' '}
        <Link
          target="_blank"
          id={'terms-of-use-btn'}
          href={'/legal/terms_of_use.pdf'}
          download={'terms of use'}
        >
          {t('terms_of_use')}
        </Link>
        {` ${t('&')} `}
        <Link
          target="_blank"
          id={'privacy_policy-btn'}
          href={'/legal/privacy_policy.pdf'}
          download={'private policy'}
        >
          {t('privacy_policy')}
        </Link>
      </Box>

      {/* <Dialog
        open={Boolean(dialogPdf)}
        onClose={handleCloseDialogPdg}
        fullWidth
        PaperProps={{ sx: { minHeight: '100dvh' } }}
      >
        <DialogContent
          sx={{
            padding: 0,
            position: 'relative',
            '& iframe': { height: '100dvh' },
          }}
        >
          {dialogPdf && (
            <iframe
              name={privatePolicy}
              src={privatePolicy}
              height="100%"
              width="100%"
              referrerPolicy="no-referrer"
            ></iframe>
          )}
        </DialogContent>

        <DialogActions>
          <Button fullWidth onClick={handleCloseDialogPdg}>
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  )
}
