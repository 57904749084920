import React from 'react'
import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import LoginForm from './LoginForm'

export default function LoginPage() {
  const { t } = useTranslation()
  return (
    <Stack
      alignItems={'center'}
      justifyContent={'center'}
      width="100dvw"
      height="100dvh"
    >
      <Typography variant="h2">{t('login.login')}</Typography>

      <LoginForm />
    </Stack>
  )
}
