import { PieChart } from '@mui/x-charts/PieChart'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function DinersClasificationPieChart({
  diners,
  dinersClassification,
}) {
  const { t } = useTranslation()
  return (
    <PieChart
      series={[
        {
          data: Object.entries(dinersClassification).map(([key, value]) => ({
            label: t(key),
            value: ((value.length * 100) / diners.length).toFixed(2),
          })),

          arcLabel: (item) => `${item.label} (${item.value.toFixed(2)})`,
          arcLabelMinAngle: 45,
          innerRadius: 150,
          outerRadius: 200,
          paddingAngle: 2,
          cornerRadius: 2,
          cx: 300,
          cy: 300,
        },
      ]}
      sx={{
        width: 800,
        height: 500,
      }}
    />
  )
}
