import _keyBy from 'lodash/keyBy'
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { useDiets } from 'hooks/diets'
import React from 'react'

export default function DinersTable({ diners, foodComponentsDict }) {
  const { diets } = useDiets()

  const dietsById = React.useMemo(() => _keyBy(diets, '_id'), [diets])

  const columns = [
    {
      name: 'phonenumber',
      accessorFn: (diner) => diner.phoneNumber,
    },
    {
      name: 'diets',
      accessorFn: (diner) =>
        diner.filters?.diets
          ?.map(
            (diet) => dietsById?.[diet]?.translations?.['en-US']?.displayName
          )
          ?.join(', '),
    },
    {
      name: 'allergies',
      accessorFn: (diner) =>
        diner.filters?.allergies
          ?.map((foodComponentId) => foodComponentsDict[foodComponentId])
          .join(', '),
    },
    {
      name: 'exclude',
      accessorFn: (diner) =>
        diner.filters?.exclude
          ?.map((foodComponentId) => foodComponentsDict[foodComponentId])
          .join(', '),
    },
    {
      name: 'avoid or reduce',
      accessorFn: (diner) =>
        diner.filters?.avoidOrReduce
          ?.map((foodComponentId) => foodComponentsDict[foodComponentId])
          .join(', '),
    },
    {
      name: 'morning conference',
      accessorFn: (diner) => diner.preferences?.morning_conference?.join(', '),
    },
    {
      name: 'conference snacks',
      accessorFn: (diner) => diner.preferences?.conference_breaks?.join(', '),
    },
    {
      name: 'lunch carbs',
      accessorFn: (diner) => diner.preferences?.main_carbs_lunch?.join(', '),
    },
    {
      name: 'lunch proteins',
      accessorFn: (diner) => diner.preferences?.main_proteins_lunch?.join(', '),
    },
    {
      name: 'where do you eat',
      accessorFn: (diner) => diner.preferences?.lunch_meal_source?.join(', '),
    },
    {
      name: 'wishlist',
      accessorFn: (diner) => diner.lunch_meal_wishlist,
    },
  ]

  return (
    <Stack overflow={'auto'} width={'100%'}>
      <TableContainer
        component={Paper}
        sx={{ mt: 2, flex: 1, maxHeight: 400, overflow: 'auto' }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              {columns.map((c) => (
                <TableCell key={c.name}>{c.name}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {diners.map((diner, index) => (
              <TableRow
                key={diner._id}
                sx={{
                  '&:last-child td, &:last-child th': {
                    border: 0,
                  },
                }}
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>

                {columns.map((c) => (
                  <TableCell key={c.name}>{c.accessorFn(diner)}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )
}
