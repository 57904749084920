import { Stack } from '@mui/material'
import React from 'react'
import Loader from './Loader'

export default function LoadingPage() {
  return (
    <Stack height="100dvh" alignItems={'center'} justifyContent={'center'}>
      <Loader />
    </Stack>
  )
}
